import { render, staticRenderFns } from "./sys_teacherDept.vue?vue&type=template&id=1a5513eb&scoped=true&"
import script from "./sys_teacherDept.vue?vue&type=script&lang=js&"
export * from "./sys_teacherDept.vue?vue&type=script&lang=js&"
import style0 from "./sys_teacherDept.vue?vue&type=style&index=0&id=1a5513eb&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5513eb",
  null
  
)

export default component.exports